.header {
    padding: 15px 0;

    button {
        min-width: 215px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.headerBottom {
    padding: 12px 0;
    background: rgba(2, 128, 144, 0.69);

    li {
        text-transform: uppercase;
        color: #FFFFFF;

        &:not(:last-child) {
            margin-right: 220px;
        }
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }
}
