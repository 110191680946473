@import "datepicker";

*, *:before, *:after {
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

html {
    font-size: 15px;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-numeric: lining-nums!important;
}

ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
}

li {
    display: inline-block;
}

a {
    text-decoration: none;
    color: inherit;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
