.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 40px;
    justify-items: center;
}

.container.loading {
    grid-template-columns: unset;
}
