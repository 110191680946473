.container {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
    border-radius: 13px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 20px 28px 280px 28px;
    margin-top: 40px;
    margin-bottom: 20px;
    position: relative;
}

.title {
    font-size: 1.13rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.closeIcon {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 18px;
    width: 1.3em!important;
    height: 1.3em!important;
    padding: 5px;
    border-radius: 50%;
    transition: .2s!important;

    &:hover {
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11%);
    }
}

.content {
    display: flex;

    & > div {
        flex-basis: 50%;
    }
}

.radioGroup {
    margin-bottom: 20px;
}

.select {
    display: block!important;
    width: 260px!important;
}

.timePickers {
    display: flex;
}

.button {
    position: absolute!important;
    bottom: 18px;
    right: 15px;
    min-width: 170px!important;
}
