.container {
    box-shadow: 0 0 26px rgba(0, 0, 0, 0.11);
    border-radius: 9px;
    padding: 28px 53px;
    margin-bottom: 55px;
}

.header {
    display: flex;
    justify-content: space-between;

    & > div:nth-child(1) {
        flex-basis: 65%;
    }

    & > div:nth-child(2) {
        * {
            display: block;
            margin-bottom: 5px;
        }
    }
}

.arrowIcon {
    transform: rotate(-90deg);
    transition: .5s!important;
    cursor: pointer;

    &_open {
        transform: rotate(-270deg);
    }
}

.title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 4px;
}

.address {
    letter-spacing: 0.045em;

    span:nth-child(1) {
        margin-right: 31px;
    }

    span:nth-child(2) {
        cursor: pointer;
        transition: .2s;
        border-bottom: 1px solid transparent;

        &:hover {
            border-color: inherit;
        }
    }
}
