.header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    h3 {
        margin-right: 25px;
        margin-bottom: 0;
    }
}

.content {
    background-color: #F2F2F2;
    border-radius: 9px;
    padding: 40px 55px;
    font-size: 1.15rem;

    & > div:nth-child(1) {
        margin-bottom: 78px;
    }
}

.additionalContent {
    display: flex;

    & > div {
        flex-basis: 50%;
    }

    .title {
        font-weight: bold;
        font-size: 1.25rem;
        margin-bottom: 7px;
    }

    ul, li {
        list-style: inherit;
    }

    li {
        display: list-item;
        margin-bottom: 4px;
    }
}
