.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 240px;
    background: #F2F2F2;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    padding: 40px 5px;
    cursor: pointer;
    transition: .3s;

    &:hover {
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    }

    span {
        margin-top: 10px;
        text-align: center;
    }
}
