@keyframes element {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.container {
    display: block;
    margin: 0 auto;
    overflow: hidden;
    background: transparent;
}

.container.small {
    width: 64px;
    height: 64px;
}

.container.medium {
    width: 100px;
    height: 100px;
}

.container.big {
    width: 200px;
    height: 200px;
}

.element {
    width: 100%;
    height: 100%;
    position: relative;
    backface-visibility: hidden;
    transform-origin: 0 0;

    div {
        position: absolute;
        border-radius: 50%;
        animation: element 1s linear infinite;
        box-sizing: content-box;
    }

    &.small, &.medium {
        div {
            width: 60px;
            height: 60px;
            border: 10px solid #50A7B2;
            top: 50px;
            left: 50px;
        }
    }

    &.small {
        transform: translateZ(0) scale(0.64);

        div { }
    }

    &.medium {
        transform: translateZ(0) scale(1);

        div { }
    }

    &.big {
        transform: translateZ(0) scale(1);

        div {
            top: 100px;
            left: 100px;
            width: 120px;
            height: 120px;
            border: 20px solid #50A7B2;
        }
    }

    &.big div, &.medium div, &.small div {
        border-top-color: transparent;
    }
}
